"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GRAYSCALE_HISTOGRAM = exports.GRAYSCALE_COLORS = void 0;
const helpers_1 = require("./helpers");
exports.GRAYSCALE_COLORS = [
    [20, 20, 20],
    [125, 125, 125],
    [250, 250, 250],
    [220, 220, 220],
    [200, 200, 200],
    [170, 170, 170],
    [80, 80, 80],
    [150, 150, 150],
    [40, 40, 40],
    [100, 100, 100]
];
exports.GRAYSCALE_HISTOGRAM = exports.GRAYSCALE_COLORS.map((rgb) => ({
    rgb,
    hex: `#${(0, helpers_1.rgbToHex)(rgb)}`,
    freq: 0.1
}));
