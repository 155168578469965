"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fallback = void 0;
class Fallback {
    constructor(primary, fallback) {
        this.primary = primary;
        this.fallback = fallback;
    }
    getAllAssetsOfOwner(ownerAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const allAssets = yield this.primary.getAllAssetsOfOwner(ownerAddress);
            return allAssets.map((nftportNftItem) => {
                if (!nftportNftItem.metadata) {
                    return Object.assign(Object.assign({}, nftportNftItem), { metadata: new Promise((resolve, reject) => {
                            this.fallback
                                .getAsset(nftportNftItem.contract_address, nftportNftItem.token_id)
                                .then((nftItem) => resolve((nftItem === null || nftItem === void 0 ? void 0 : nftItem.metadata) || null))
                                .catch((a) => resolve(null));
                        }) });
                }
                else {
                    return Object.assign(Object.assign({}, nftportNftItem), { name: nftportNftItem.name || nftportNftItem.metadata.name, google_image: nftportNftItem.cached_file_url });
                }
            });
        });
    }
    canBeContinued() {
        return this.primary.canBeContinued();
    }
    reset() {
        if (this.canBeContinued()) {
            this.primary.reset();
        }
    }
    getAsset(collection, tokenId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.fallback.getAsset(collection, tokenId);
        });
    }
}
exports.Fallback = Fallback;
