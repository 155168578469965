"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IERC20Metadata__factory = exports.ERC20Pausable__factory = exports.ERC20Burnable__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var ERC20Burnable__factory_1 = require("./ERC20Burnable__factory");
Object.defineProperty(exports, "ERC20Burnable__factory", { enumerable: true, get: function () { return ERC20Burnable__factory_1.ERC20Burnable__factory; } });
var ERC20Pausable__factory_1 = require("./ERC20Pausable__factory");
Object.defineProperty(exports, "ERC20Pausable__factory", { enumerable: true, get: function () { return ERC20Pausable__factory_1.ERC20Pausable__factory; } });
var IERC20Metadata__factory_1 = require("./IERC20Metadata__factory");
Object.defineProperty(exports, "IERC20Metadata__factory", { enumerable: true, get: function () { return IERC20Metadata__factory_1.IERC20Metadata__factory; } });
