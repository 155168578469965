"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Withdrawable__factory = exports.ChainWallet__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var ChainWallet__factory_1 = require("./ChainWallet__factory");
Object.defineProperty(exports, "ChainWallet__factory", { enumerable: true, get: function () { return ChainWallet__factory_1.ChainWallet__factory; } });
var Withdrawable__factory_1 = require("./Withdrawable__factory");
Object.defineProperty(exports, "Withdrawable__factory", { enumerable: true, get: function () { return Withdrawable__factory_1.Withdrawable__factory; } });
