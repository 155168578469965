"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnChain = void 0;
const __1 = require("..");
const NFTIndexer_1 = require("./NFTIndexer");
class OnChain {
    constructor(provider, addressList, options) {
        this.collections = {};
        this.options = options || {};
        this.provider = provider;
        addressList.forEach((knownCollection) => {
            this.collections[knownCollection] = (0, __1.erc721Enumerable)(provider, knownCollection);
        });
    }
    getCollections() {
        return Object.values(this.collections);
    }
    canBeContinued() {
        return false;
    }
    reset() {
        return;
    }
    getAllAssetsOfOwner(ownerAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const items = yield Promise.all(Object.keys(this.collections).map((coll) => this.getNFTsOfOwner(coll, ownerAddress)));
            const onlyWorkingAssets = (items.flatMap((i) => i).filter((i) => i != null));
            return onlyWorkingAssets;
        });
    }
    getNFTsOfOwner(collection, ownerAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const contract = this.collections[collection] ||
                (0, __1.erc721Enumerable)(this.provider, collection);
            const bal = (yield contract.balanceOf(ownerAddress)).toNumber();
            const promises = [];
            for (let i = 0; i < bal; i++) {
                promises.push((() => __awaiter(this, void 0, void 0, function* () {
                    const tokenId = yield contract.tokenOfOwnerByIndex(ownerAddress, i);
                    try {
                        const metaData = (0, NFTIndexer_1.fetchMetadataFromUrl)((0, __1.ipfsGW)(yield contract.tokenURI(tokenId)), this.options.proxyAddress);
                        return {
                            contract_address: contract.address,
                            token_id: tokenId.toString(),
                            metadata: metaData
                        };
                    }
                    catch (e) {
                        console.warn(`couldnt load ${collection}/${tokenId}: ${e.message}`);
                        return {
                            contract_address: contract.address,
                            token_id: tokenId.toString(),
                            metadata: null
                        };
                    }
                }))());
            }
            return Promise.all(promises);
        });
    }
    getAsset(collection, tokenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const contract = this.collections[collection] ||
                (0, __1.erc721Enumerable)(this.provider, collection);
            const tokenUri = yield contract.tokenURI(tokenId);
            const metadataUrl = (0, __1.ipfsGW)(tokenUri);
            const metadata = yield (0, NFTIndexer_1.fetchMetadataFromUrl)(metadataUrl, this.options.proxyAddress);
            return {
                contract_address: collection,
                token_id: tokenId,
                name: metadata.name,
                description: metadata.description,
                metadata
            };
        });
    }
}
exports.OnChain = OnChain;
