"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveStyle = void 0;
const contracts_1 = require("@splicenft/contracts");
class ActiveStyle {
    constructor(style, contract) {
        this.style = style;
        this.contract = contract;
    }
    get tokenId() {
        return this.style.tokenId;
    }
    getCode() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.style.getCode();
        });
    }
    getMetadata() {
        return this.style.getMetadata();
    }
    getRenderer() {
        return this.style.getRenderer();
    }
    getMetadataUrl() {
        return this.style.getMetadataUrl();
    }
    getCollectionAddress() {
        return this.contract.address;
    }
    stats() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._stats)
                return this._stats;
            const settings = yield this.contract.getSettings(this.tokenId);
            const owner = yield this.contract.ownerOf(this.tokenId);
            // const active = await this.contract.isSaleActive(this.tokenId);
            // const reserved = await this.contract.reservedTokens(this.tokenId);
            this._stats = {
                style: {
                    id: this.tokenId.toString(),
                    cap: settings.cap,
                    minted: settings.mintedOfStyle,
                    owner,
                    priceStrategy: settings.priceStrategy,
                    split: {
                        payments: []
                    }
                }
            };
            return this._stats;
        });
    }
    quote(collection, tokenId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.contract.quoteFee(this.tokenId, [collection], [tokenId]);
        });
    }
    isMintable(collections, tokenIds, minter) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.contract.isMintable(this.tokenId, collections, tokenIds, minter);
                return result;
            }
            catch (e) {
                if (!((_a = e.data) === null || _a === void 0 ? void 0 : _a.message))
                    return e.message;
                const xRegx = /^.*'(.*)'$/gi;
                const res = xRegx.exec(e.data.message);
                return res ? res[1] : 'Unknown error occurred during isMintable query';
            }
        });
    }
    toggleActive(newVal) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.contract.toggleSaleIsActive(this.tokenId, newVal);
            return newVal;
        });
    }
    isStyleActive() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.contract.isSaleActive(this.tokenId);
        });
    }
    settings() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._settings)
                return this._settings;
            this._settings = yield this.contract.getSettings(this.tokenId);
            return this._settings;
        });
    }
    paymentSplitter() {
        return __awaiter(this, void 0, void 0, function* () {
            return contracts_1.ReplaceablePaymentSplitter__factory.connect((yield this.settings()).paymentSplitter, this.contract.provider);
        });
    }
    priceStrategy() {
        return __awaiter(this, void 0, void 0, function* () {
            return contracts_1.ISplicePriceStrategy__factory.connect((yield this.settings()).priceStrategy, this.contract.provider);
        });
    }
    partnership() {
        return __awaiter(this, void 0, void 0, function* () {
            const partnership = yield this.contract.getPartnership(this.tokenId);
            if (partnership.collections.length === 0)
                return undefined;
            return {
                collections: partnership.collections,
                exclusive: partnership.exclusive,
                until: new Date(partnership.until.toNumber() * 1000)
            };
        });
    }
    isStyleMinter(account) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.contract.isStyleMinter(account);
        });
    }
}
exports.ActiveStyle = ActiveStyle;
