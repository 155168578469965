"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestnetNFT__factory = exports.TestPaymentSplitterController__factory = exports.SpliceStyleNFT__factory = exports.SplicePriceStrategyStatic__factory = exports.Splice__factory = exports.ReplaceablePaymentSplitter__factory = exports.PaymentSplitterController__factory = exports.ISplicePriceStrategy__factory = exports.testErc20Sol = exports.chainWalletSol = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
exports.chainWalletSol = __importStar(require("./ChainWallet.sol"));
exports.testErc20Sol = __importStar(require("./TestERC20.sol"));
var ISplicePriceStrategy__factory_1 = require("./ISplicePriceStrategy__factory");
Object.defineProperty(exports, "ISplicePriceStrategy__factory", { enumerable: true, get: function () { return ISplicePriceStrategy__factory_1.ISplicePriceStrategy__factory; } });
var PaymentSplitterController__factory_1 = require("./PaymentSplitterController__factory");
Object.defineProperty(exports, "PaymentSplitterController__factory", { enumerable: true, get: function () { return PaymentSplitterController__factory_1.PaymentSplitterController__factory; } });
var ReplaceablePaymentSplitter__factory_1 = require("./ReplaceablePaymentSplitter__factory");
Object.defineProperty(exports, "ReplaceablePaymentSplitter__factory", { enumerable: true, get: function () { return ReplaceablePaymentSplitter__factory_1.ReplaceablePaymentSplitter__factory; } });
var Splice__factory_1 = require("./Splice__factory");
Object.defineProperty(exports, "Splice__factory", { enumerable: true, get: function () { return Splice__factory_1.Splice__factory; } });
var SplicePriceStrategyStatic__factory_1 = require("./SplicePriceStrategyStatic__factory");
Object.defineProperty(exports, "SplicePriceStrategyStatic__factory", { enumerable: true, get: function () { return SplicePriceStrategyStatic__factory_1.SplicePriceStrategyStatic__factory; } });
var SpliceStyleNFT__factory_1 = require("./SpliceStyleNFT__factory");
Object.defineProperty(exports, "SpliceStyleNFT__factory", { enumerable: true, get: function () { return SpliceStyleNFT__factory_1.SpliceStyleNFT__factory; } });
var TestPaymentSplitterController__factory_1 = require("./TestPaymentSplitterController__factory");
Object.defineProperty(exports, "TestPaymentSplitterController__factory", { enumerable: true, get: function () { return TestPaymentSplitterController__factory_1.TestPaymentSplitterController__factory; } });
var TestnetNFT__factory_1 = require("./TestnetNFT__factory");
Object.defineProperty(exports, "TestnetNFT__factory", { enumerable: true, get: function () { return TestnetNFT__factory_1.TestnetNFT__factory; } });
