"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.palette = void 0;
const iq = __importStar(require("image-q"));
const helpers_1 = require("./helpers");
const histogram = (pc) => {
    const p = pc.toUint32Array();
    const buckets = {};
    for (let i = 0; i < p.length; i++) {
        if (!buckets[p[i]]) {
            buckets[p[i]] = 0;
        }
        buckets[p[i]]++;
    }
    return Object.entries(buckets).sort(([, a], [, b]) => b - a);
};
function palette(rgba, dims, amount = 10) {
    const pointContainer = iq.utils.PointContainer.fromUint8Array(rgba, dims.w, dims.h);
    const distanceCalculator = new iq.distance.EuclideanBT709NoAlpha();
    const paletteQuantizer = new iq.palette.WuQuant(distanceCalculator, amount);
    paletteQuantizer.sample(pointContainer);
    const qPalette = paletteQuantizer.quantizeSync();
    const quantizedImage = iq.applyPaletteSync(pointContainer, qPalette, {
        colorDistanceFormula: 'euclidean-bt709-noalpha',
        imageQuantization: 'nearest'
    });
    //const histogram = new iq.palette.ColorHistogram(2, amount);
    //histogram.sample(quantizedImage);
    //const iqH = histogram.getImportanceSortedColorsIDXI32().map(i32ToRGB);
    //console.log('iqh', iqH);
    const buckets = histogram(quantizedImage);
    const len = quantizedImage.getPointArray().length;
    return buckets.map((b) => {
        const rgb = (0, helpers_1.i32ToRGB)(+b[0]);
        return {
            rgb,
            hex: '#' + (0, helpers_1.rgbToHex)(rgb),
            freq: b[1] / len
        };
    });
}
exports.palette = palette;
