"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainWallet__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
const ethers_1 = require("ethers");
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "by",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "val",
                type: "uint256",
            },
        ],
        name: "Received",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "splice",
                type: "address",
            },
        ],
        name: "withdrawShares",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        stateMutability: "payable",
        type: "receive",
    },
];
const _bytecode = "0x608060405234801561001057600080fd5b5061014a806100206000396000f3fe6080604052600436106100225760003560e01c8063ed03d0da1461006657600080fd5b3661006157604080513381523460208201527f88a5966d370b9919b20f3e2c13ff65706f196a4e32cc2c12bf57088f88525874910160405180910390a1005b600080fd5b34801561007257600080fd5b506100866100813660046100e4565b610088565b005b60405163798e586360e11b81523060048201526001600160a01b0382169063f31cb0c690602401600060405180830381600087803b1580156100c957600080fd5b505af11580156100dd573d6000803e3d6000fd5b5050505050565b6000602082840312156100f657600080fd5b81356001600160a01b038116811461010d57600080fd5b939250505056fea26469706673582212207683890cf9ba07840eb904d6f9466dec78dbad392e20c56bbfffb458e20ec9f964736f6c634300080a0033";
const isSuperArgs = (xs) => xs.length > 1;
class ChainWallet__factory extends ethers_1.ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.ChainWallet__factory = ChainWallet__factory;
ChainWallet__factory.bytecode = _bytecode;
ChainWallet__factory.abi = _abi;
