"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IERC165Upgradeable__factory = exports.ERC165Upgradeable__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var ERC165Upgradeable__factory_1 = require("./ERC165Upgradeable__factory");
Object.defineProperty(exports, "ERC165Upgradeable__factory", { enumerable: true, get: function () { return ERC165Upgradeable__factory_1.ERC165Upgradeable__factory; } });
var IERC165Upgradeable__factory_1 = require("./IERC165Upgradeable__factory");
Object.defineProperty(exports, "IERC165Upgradeable__factory", { enumerable: true, get: function () { return IERC165Upgradeable__factory_1.IERC165Upgradeable__factory; } });
