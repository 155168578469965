"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ownable__factory = exports.IAccessControlEnumerable__factory = exports.IAccessControl__factory = exports.AccessControlEnumerable__factory = exports.AccessControl__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var AccessControl__factory_1 = require("./AccessControl__factory");
Object.defineProperty(exports, "AccessControl__factory", { enumerable: true, get: function () { return AccessControl__factory_1.AccessControl__factory; } });
var AccessControlEnumerable__factory_1 = require("./AccessControlEnumerable__factory");
Object.defineProperty(exports, "AccessControlEnumerable__factory", { enumerable: true, get: function () { return AccessControlEnumerable__factory_1.AccessControlEnumerable__factory; } });
var IAccessControl__factory_1 = require("./IAccessControl__factory");
Object.defineProperty(exports, "IAccessControl__factory", { enumerable: true, get: function () { return IAccessControl__factory_1.IAccessControl__factory; } });
var IAccessControlEnumerable__factory_1 = require("./IAccessControlEnumerable__factory");
Object.defineProperty(exports, "IAccessControlEnumerable__factory", { enumerable: true, get: function () { return IAccessControlEnumerable__factory_1.IAccessControlEnumerable__factory; } });
var Ownable__factory_1 = require("./Ownable__factory");
Object.defineProperty(exports, "Ownable__factory", { enumerable: true, get: function () { return Ownable__factory_1.Ownable__factory; } });
