"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestPaymentSplitterController__factory = exports.TestnetNFT__factory = exports.GLDToken__factory = exports.SpliceStyleNFT__factory = exports.SplicePriceStrategyStatic__factory = exports.Splice__factory = exports.ReplaceablePaymentSplitter__factory = exports.PaymentSplitterController__factory = exports.ISplicePriceStrategy__factory = exports.Withdrawable__factory = exports.ChainWallet__factory = exports.IERC165__factory = exports.ERC165__factory = exports.IERC721Receiver__factory = exports.IERC721__factory = exports.IERC721Metadata__factory = exports.IERC721Enumerable__factory = exports.ERC721Enumerable__factory = exports.ERC721__factory = exports.ERC20PresetMinterPauser__factory = exports.IERC20__factory = exports.IERC20Metadata__factory = exports.ERC20Pausable__factory = exports.ERC20Burnable__factory = exports.ERC20__factory = exports.Pausable__factory = exports.Ownable__factory = exports.IAccessControlEnumerable__factory = exports.IAccessControl__factory = exports.AccessControlEnumerable__factory = exports.AccessControl__factory = exports.IERC165Upgradeable__factory = exports.ERC165Upgradeable__factory = exports.IERC721Upgradeable__factory = exports.IERC721ReceiverUpgradeable__factory = exports.IERC721MetadataUpgradeable__factory = exports.IERC721EnumerableUpgradeable__factory = exports.ERC721EnumerableUpgradeable__factory = exports.ERC721Upgradeable__factory = exports.PausableUpgradeable__factory = exports.IERC2981Upgradeable__factory = exports.OwnableUpgradeable__factory = exports.factories = void 0;
exports.factories = __importStar(require("./factories"));
var OwnableUpgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/access/OwnableUpgradeable__factory");
Object.defineProperty(exports, "OwnableUpgradeable__factory", { enumerable: true, get: function () { return OwnableUpgradeable__factory_1.OwnableUpgradeable__factory; } });
var IERC2981Upgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/interfaces/IERC2981Upgradeable__factory");
Object.defineProperty(exports, "IERC2981Upgradeable__factory", { enumerable: true, get: function () { return IERC2981Upgradeable__factory_1.IERC2981Upgradeable__factory; } });
var PausableUpgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/security/PausableUpgradeable__factory");
Object.defineProperty(exports, "PausableUpgradeable__factory", { enumerable: true, get: function () { return PausableUpgradeable__factory_1.PausableUpgradeable__factory; } });
var ERC721Upgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/token/ERC721/ERC721Upgradeable__factory");
Object.defineProperty(exports, "ERC721Upgradeable__factory", { enumerable: true, get: function () { return ERC721Upgradeable__factory_1.ERC721Upgradeable__factory; } });
var ERC721EnumerableUpgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/token/ERC721/extensions/ERC721EnumerableUpgradeable__factory");
Object.defineProperty(exports, "ERC721EnumerableUpgradeable__factory", { enumerable: true, get: function () { return ERC721EnumerableUpgradeable__factory_1.ERC721EnumerableUpgradeable__factory; } });
var IERC721EnumerableUpgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/token/ERC721/extensions/IERC721EnumerableUpgradeable__factory");
Object.defineProperty(exports, "IERC721EnumerableUpgradeable__factory", { enumerable: true, get: function () { return IERC721EnumerableUpgradeable__factory_1.IERC721EnumerableUpgradeable__factory; } });
var IERC721MetadataUpgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/token/ERC721/extensions/IERC721MetadataUpgradeable__factory");
Object.defineProperty(exports, "IERC721MetadataUpgradeable__factory", { enumerable: true, get: function () { return IERC721MetadataUpgradeable__factory_1.IERC721MetadataUpgradeable__factory; } });
var IERC721ReceiverUpgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/token/ERC721/IERC721ReceiverUpgradeable__factory");
Object.defineProperty(exports, "IERC721ReceiverUpgradeable__factory", { enumerable: true, get: function () { return IERC721ReceiverUpgradeable__factory_1.IERC721ReceiverUpgradeable__factory; } });
var IERC721Upgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/token/ERC721/IERC721Upgradeable__factory");
Object.defineProperty(exports, "IERC721Upgradeable__factory", { enumerable: true, get: function () { return IERC721Upgradeable__factory_1.IERC721Upgradeable__factory; } });
var ERC165Upgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/utils/introspection/ERC165Upgradeable__factory");
Object.defineProperty(exports, "ERC165Upgradeable__factory", { enumerable: true, get: function () { return ERC165Upgradeable__factory_1.ERC165Upgradeable__factory; } });
var IERC165Upgradeable__factory_1 = require("./factories/@openzeppelin/contracts-upgradeable/utils/introspection/IERC165Upgradeable__factory");
Object.defineProperty(exports, "IERC165Upgradeable__factory", { enumerable: true, get: function () { return IERC165Upgradeable__factory_1.IERC165Upgradeable__factory; } });
var AccessControl__factory_1 = require("./factories/@openzeppelin/contracts/access/AccessControl__factory");
Object.defineProperty(exports, "AccessControl__factory", { enumerable: true, get: function () { return AccessControl__factory_1.AccessControl__factory; } });
var AccessControlEnumerable__factory_1 = require("./factories/@openzeppelin/contracts/access/AccessControlEnumerable__factory");
Object.defineProperty(exports, "AccessControlEnumerable__factory", { enumerable: true, get: function () { return AccessControlEnumerable__factory_1.AccessControlEnumerable__factory; } });
var IAccessControl__factory_1 = require("./factories/@openzeppelin/contracts/access/IAccessControl__factory");
Object.defineProperty(exports, "IAccessControl__factory", { enumerable: true, get: function () { return IAccessControl__factory_1.IAccessControl__factory; } });
var IAccessControlEnumerable__factory_1 = require("./factories/@openzeppelin/contracts/access/IAccessControlEnumerable__factory");
Object.defineProperty(exports, "IAccessControlEnumerable__factory", { enumerable: true, get: function () { return IAccessControlEnumerable__factory_1.IAccessControlEnumerable__factory; } });
var Ownable__factory_1 = require("./factories/@openzeppelin/contracts/access/Ownable__factory");
Object.defineProperty(exports, "Ownable__factory", { enumerable: true, get: function () { return Ownable__factory_1.Ownable__factory; } });
var Pausable__factory_1 = require("./factories/@openzeppelin/contracts/security/Pausable__factory");
Object.defineProperty(exports, "Pausable__factory", { enumerable: true, get: function () { return Pausable__factory_1.Pausable__factory; } });
var ERC20__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/ERC20__factory");
Object.defineProperty(exports, "ERC20__factory", { enumerable: true, get: function () { return ERC20__factory_1.ERC20__factory; } });
var ERC20Burnable__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/extensions/ERC20Burnable__factory");
Object.defineProperty(exports, "ERC20Burnable__factory", { enumerable: true, get: function () { return ERC20Burnable__factory_1.ERC20Burnable__factory; } });
var ERC20Pausable__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/extensions/ERC20Pausable__factory");
Object.defineProperty(exports, "ERC20Pausable__factory", { enumerable: true, get: function () { return ERC20Pausable__factory_1.ERC20Pausable__factory; } });
var IERC20Metadata__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/extensions/IERC20Metadata__factory");
Object.defineProperty(exports, "IERC20Metadata__factory", { enumerable: true, get: function () { return IERC20Metadata__factory_1.IERC20Metadata__factory; } });
var IERC20__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/IERC20__factory");
Object.defineProperty(exports, "IERC20__factory", { enumerable: true, get: function () { return IERC20__factory_1.IERC20__factory; } });
var ERC20PresetMinterPauser__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC20/presets/ERC20PresetMinterPauser__factory");
Object.defineProperty(exports, "ERC20PresetMinterPauser__factory", { enumerable: true, get: function () { return ERC20PresetMinterPauser__factory_1.ERC20PresetMinterPauser__factory; } });
var ERC721__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/ERC721__factory");
Object.defineProperty(exports, "ERC721__factory", { enumerable: true, get: function () { return ERC721__factory_1.ERC721__factory; } });
var ERC721Enumerable__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/extensions/ERC721Enumerable__factory");
Object.defineProperty(exports, "ERC721Enumerable__factory", { enumerable: true, get: function () { return ERC721Enumerable__factory_1.ERC721Enumerable__factory; } });
var IERC721Enumerable__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/extensions/IERC721Enumerable__factory");
Object.defineProperty(exports, "IERC721Enumerable__factory", { enumerable: true, get: function () { return IERC721Enumerable__factory_1.IERC721Enumerable__factory; } });
var IERC721Metadata__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/extensions/IERC721Metadata__factory");
Object.defineProperty(exports, "IERC721Metadata__factory", { enumerable: true, get: function () { return IERC721Metadata__factory_1.IERC721Metadata__factory; } });
var IERC721__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/IERC721__factory");
Object.defineProperty(exports, "IERC721__factory", { enumerable: true, get: function () { return IERC721__factory_1.IERC721__factory; } });
var IERC721Receiver__factory_1 = require("./factories/@openzeppelin/contracts/token/ERC721/IERC721Receiver__factory");
Object.defineProperty(exports, "IERC721Receiver__factory", { enumerable: true, get: function () { return IERC721Receiver__factory_1.IERC721Receiver__factory; } });
var ERC165__factory_1 = require("./factories/@openzeppelin/contracts/utils/introspection/ERC165__factory");
Object.defineProperty(exports, "ERC165__factory", { enumerable: true, get: function () { return ERC165__factory_1.ERC165__factory; } });
var IERC165__factory_1 = require("./factories/@openzeppelin/contracts/utils/introspection/IERC165__factory");
Object.defineProperty(exports, "IERC165__factory", { enumerable: true, get: function () { return IERC165__factory_1.IERC165__factory; } });
var ChainWallet__factory_1 = require("./factories/contracts/ChainWallet.sol/ChainWallet__factory");
Object.defineProperty(exports, "ChainWallet__factory", { enumerable: true, get: function () { return ChainWallet__factory_1.ChainWallet__factory; } });
var Withdrawable__factory_1 = require("./factories/contracts/ChainWallet.sol/Withdrawable__factory");
Object.defineProperty(exports, "Withdrawable__factory", { enumerable: true, get: function () { return Withdrawable__factory_1.Withdrawable__factory; } });
var ISplicePriceStrategy__factory_1 = require("./factories/contracts/ISplicePriceStrategy__factory");
Object.defineProperty(exports, "ISplicePriceStrategy__factory", { enumerable: true, get: function () { return ISplicePriceStrategy__factory_1.ISplicePriceStrategy__factory; } });
var PaymentSplitterController__factory_1 = require("./factories/contracts/PaymentSplitterController__factory");
Object.defineProperty(exports, "PaymentSplitterController__factory", { enumerable: true, get: function () { return PaymentSplitterController__factory_1.PaymentSplitterController__factory; } });
var ReplaceablePaymentSplitter__factory_1 = require("./factories/contracts/ReplaceablePaymentSplitter__factory");
Object.defineProperty(exports, "ReplaceablePaymentSplitter__factory", { enumerable: true, get: function () { return ReplaceablePaymentSplitter__factory_1.ReplaceablePaymentSplitter__factory; } });
var Splice__factory_1 = require("./factories/contracts/Splice__factory");
Object.defineProperty(exports, "Splice__factory", { enumerable: true, get: function () { return Splice__factory_1.Splice__factory; } });
var SplicePriceStrategyStatic__factory_1 = require("./factories/contracts/SplicePriceStrategyStatic__factory");
Object.defineProperty(exports, "SplicePriceStrategyStatic__factory", { enumerable: true, get: function () { return SplicePriceStrategyStatic__factory_1.SplicePriceStrategyStatic__factory; } });
var SpliceStyleNFT__factory_1 = require("./factories/contracts/SpliceStyleNFT__factory");
Object.defineProperty(exports, "SpliceStyleNFT__factory", { enumerable: true, get: function () { return SpliceStyleNFT__factory_1.SpliceStyleNFT__factory; } });
var GLDToken__factory_1 = require("./factories/contracts/TestERC20.sol/GLDToken__factory");
Object.defineProperty(exports, "GLDToken__factory", { enumerable: true, get: function () { return GLDToken__factory_1.GLDToken__factory; } });
var TestnetNFT__factory_1 = require("./factories/contracts/TestnetNFT__factory");
Object.defineProperty(exports, "TestnetNFT__factory", { enumerable: true, get: function () { return TestnetNFT__factory_1.TestnetNFT__factory; } });
var TestPaymentSplitterController__factory_1 = require("./factories/contracts/TestPaymentSplitterController__factory");
Object.defineProperty(exports, "TestPaymentSplitterController__factory", { enumerable: true, get: function () { return TestPaymentSplitterController__factory_1.TestPaymentSplitterController__factory; } });
