"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyAllowlistEntry = exports.AllowlistTypes = void 0;
const ethers_1 = require("ethers");
exports.AllowlistTypes = {
    AllowlistRequest: [
        { name: 'style_token_id', type: 'string' },
        { name: 'from', type: 'address' }
    ]
};
function verifyAllowlistEntry(chainId, styleTokenId, account, signature) {
    const verifiedAddress = ethers_1.ethers.utils.verifyTypedData({
        chainId: chainId,
        name: 'Splice Allowlist',
        version: '1'
    }, exports.AllowlistTypes, {
        style_token_id: styleTokenId,
        from: account
    }, signature);
    return verifiedAddress === account;
}
exports.verifyAllowlistEntry = verifyAllowlistEntry;
