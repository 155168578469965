"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataUriToBlob = exports.getIpfsPath = exports.isIpfsLocation = exports.isIpfsGateway = exports.ipfsGW = exports.resolveImage = void 0;
//const IPFS_GATEWAY = 'https://ipfs.io';
//const IPFS_GATEWAY = 'https://dweb.link';
const IPFS_GATEWAY = 'https://ipfs2.getsplice.io';
const IPFS_LOCATION_REGEX = new RegExp(`/ipfs/(.+)`, 'gi');
const resolveImage = (nftMetaData) => {
    const imgUrl = nftMetaData.google_image || nftMetaData.image_url || nftMetaData.image;
    //console.log(imgUrl);
    return imgUrl ? (0, exports.ipfsGW)(imgUrl) : '';
};
exports.resolveImage = resolveImage;
const ipfsGW = (url) => {
    if (url.startsWith('ipfs://ipfs/'))
        return url.replace('ipfs://ipfs/', `${IPFS_GATEWAY}/ipfs/`);
    if (url.startsWith('ipfs://')) {
        return url.replace('ipfs://', `${IPFS_GATEWAY}/ipfs/`);
    }
    else {
        const i = url.indexOf('/ipfs/');
        if (i !== -1) {
            return `${IPFS_GATEWAY}${url.slice(i)}`;
        }
        return url;
    }
};
exports.ipfsGW = ipfsGW;
const isIpfsGateway = (url) => {
    return url.startsWith(IPFS_GATEWAY);
};
exports.isIpfsGateway = isIpfsGateway;
const isIpfsLocation = (url) => {
    return !!url.match(IPFS_LOCATION_REGEX);
};
exports.isIpfsLocation = isIpfsLocation;
const getIpfsPath = (url) => {
    if (!(0, exports.isIpfsLocation)(url)) {
        throw new Error('not an ipfs path');
    }
    const matches = url.matchAll(IPFS_LOCATION_REGEX);
    return matches.next().value[1];
};
exports.getIpfsPath = getIpfsPath;
const dataUriToBlob = (dataUri) => {
    const mime = dataUri.split(',')[0].split(':')[1].split(';')[0];
    const binary = atob(dataUri.split(',')[1]);
    const arr = [];
    for (let i = 0; i < binary.length; i++) {
        arr.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(arr)], { type: mime });
};
exports.dataUriToBlob = dataUriToBlob;
