"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ethers_1 = require("ethers");
exports.default = (network, options) => {
    let provider;
    if (network.startsWith('http')) {
        provider = new ethers_1.providers.JsonRpcProvider(network);
    }
    else {
        provider = new ethers_1.providers.InfuraWebSocketProvider(network, options.infuraKey);
    }
    let wallet;
    if (options.privateKey) {
        wallet = new ethers_1.Wallet(options.privateKey, provider);
    }
    return {
        provider,
        signer: wallet
    };
};
